import { ref, Ref, unref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { findLastIndex } from "lodash";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { transformSeriesData } from "@/utils/number";
import { MaybeRef, DecoratedProperty, TimeSeries, GqlTimeSeries, ParamProvider, UnitConversionFn } from "@/types";

const DURATION_PERIOD = [
  { days: 0, period: 0 },
  { days: 1, period: 60 * 15 },
  { days: 3, period: 60 * 30 },
  { days: 7, period: 60 * 60 },
  { days: 14, period: 60 * 60 * 6 }
];

export interface UsePropertyTrendResult {
  series: Ref<TimeSeries | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function usePropertyTrend(
  assetUuid: MaybeRef<string>,
  property: MaybeRef<DecoratedProperty>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  convertFn: UnitConversionFn | undefined,
  convertParams: MaybeRef<ParamProvider> | undefined = undefined,
  period: MaybeRef<number> | undefined = undefined
): UsePropertyTrendResult {
  const seriesData = ref<GqlTimeSeries>();
  const loadingError = ref(false);

  const effectivePeriod = computed(() => {
    const passedPeriod = unref(period);
    if (passedPeriod) return passedPeriod;

    const days = unref(endDate).diff(unref(startDate), "days").days;
    const index = findLastIndex(DURATION_PERIOD, g => days >= g.days);
    return DURATION_PERIOD[index].period;
  });

  const queryVariables = computed(() => {
    return {
      deviceUuid: unref(assetUuid),
      property: unref(property).config.key ?? unref(property).name,
      aggregation: unref(property).config.aggregation,
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: effectivePeriod.value
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query PropertyTrend(
        $deviceUuid: ID!
        $property: String!
        $startDate: DateTime!
        $endDate: DateTime!
        $period: Int!
        $aggregation: AggregationType!
      ) {
        series: propertyTrend(
          deviceUuid: $deviceUuid
          filter: {
            property: $property
            startDate: $startDate
            endDate: $endDate
            period: $period
            aggregation: $aggregation
          }
        )
      }
    `,
    queryVariables
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      seriesData.value = queryResult.data.series;
    }
  });

  const series = computed(() => {
    if (!seriesData.value) return undefined;

    const { name } = unref(property);
    return transformSeriesData(seriesData.value, name, convertFn, unref(convertParams));
  });

  return { series, loadingError, refetch, loading };
}
